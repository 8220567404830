import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { motion } from 'framer-motion';
import '../../styles/pages/Legal.css';

const TermsOfUse = () => {
  const fadeUpVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1.0]
      }
    }
  };

  return (
    <div className="legal-page">
      <Navbar />
      
      <motion.div
        className="hero-section"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1589829545856-d10d557cf95f?auto=format&fit=crop&q=80")'
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            Terms of Use
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="hero-subtitle"
          >
            Understanding Our Agreement
          </motion.p>
        </div>
      </motion.div>

      <motion.section
        className="legal-content-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.div className="section-content" variants={fadeUpVariants}>
          <div className="legal-content">
            <h2>1. Introduction</h2>
            <p>Welcome to Glenhouse. By accessing or using our website and services, you agree to these Terms of Use. Please read them carefully.</p>

            <h2>2. Acceptance of Terms</h2>
            <p>By accessing or using the Glenhouse website and services, you acknowledge that you have read, understood, and agree to be bound by these Terms of Use.</p>

            <h2>3. Services Description</h2>
            <p>Glenhouse provides investment and business development services across multiple sectors. Our platform offers information about our services, portfolio companies, and investment approach.</p>

            <h2>4. User Obligations</h2>
            <p>You agree to use our services only for lawful purposes and in accordance with these Terms. You must not use our services in any way that could damage, disable, or impair our platforms.</p>

            <h2>5. Intellectual Property</h2>
            <p>All content, trademarks, and intellectual property on our website belong to Glenhouse. You may not use, reproduce, or distribute our content without explicit permission.</p>

            <h2>6. Privacy and Data Protection</h2>
            <p>Your use of our services is also governed by our Privacy Policy. Please review it to understand how we collect and use your information.</p>

            <h2>7. Limitation of Liability</h2>
            <p>Glenhouse shall not be liable for any indirect, incidental, special, consequential, or punitive damages resulting from your use or inability to use our services.</p>

            <h2>8. Modifications</h2>
            <p>We reserve the right to modify these Terms at any time. Continued use of our services after changes constitutes acceptance of the modified Terms.</p>

            <h2>9. Governing Law</h2>
            <p>These Terms shall be governed by and construed in accordance with the laws of the jurisdiction where Glenhouse is registered.</p>

            <h2>10. Contact Information</h2>
            <p>For questions about these Terms, please contact us at legal@weareglenhouse.com.</p>
          </div>
        </motion.div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default TermsOfUse; 