import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { motion } from 'framer-motion';
import '../../styles/pages/Legal.css';

const CookiePolicy = () => {
  const fadeUpVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1.0]
      }
    }
  };

  return (
    <div className="legal-page">
      <Navbar />
      
      <motion.div
        className="hero-section"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1557264337-e8a93017fe92?auto=format&fit=crop&q=80")'
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            Cookie Policy
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="hero-subtitle"
          >
            Understanding Our Use of Cookies
          </motion.p>
        </div>
      </motion.div>

      <motion.section
        className="legal-content-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.div className="section-content" variants={fadeUpVariants}>
          <div className="legal-content">
            <h2>1. What Are Cookies?</h2>
            <p>Cookies are small text files that are placed on your device when you visit our website. They help us provide you with a better experience and allow certain features to work.</p>

            <h2>2. Types of Cookies We Use</h2>
            <p>We use several types of cookies on our website:</p>
            <ul>
              <li>Essential cookies: Required for basic website functionality</li>
              <li>Performance cookies: Help us understand how visitors use our site</li>
              <li>Functionality cookies: Remember your preferences and choices</li>
              <li>Targeting cookies: Deliver more relevant advertisements</li>
            </ul>

            <h2>3. How We Use Cookies</h2>
            <p>We use cookies to:</p>
            <ul>
              <li>Remember your preferences and settings</li>
              <li>Understand how you use our website</li>
              <li>Improve our services and user experience</li>
              <li>Provide personalized content and advertising</li>
            </ul>

            <h2>4. Managing Cookies</h2>
            <p>You can control and manage cookies in your browser settings. You can:</p>
            <ul>
              <li>Delete all cookies from your browser</li>
              <li>Block all cookies from being set</li>
              <li>Allow only certain cookies to be set</li>
              <li>Clear cookies when you close your browser</li>
            </ul>

            <h2>5. Third-Party Cookies</h2>
            <p>Some cookies are placed by third-party services that appear on our pages. We do not control these cookies and recommend reviewing the privacy policies of these third parties.</p>

            <h2>6. Impact of Disabling Cookies</h2>
            <p>Disabling cookies may affect the functionality of our website. Some features may not work properly if cookies are disabled.</p>

            <h2>7. Updates to This Policy</h2>
            <p>We may update this Cookie Policy from time to time. Any changes will be posted on this page with an updated revision date.</p>

            <h2>8. Contact Us</h2>
            <p>If you have questions about our Cookie Policy, please contact us at privacy@weareglenhouse.com.</p>
          </div>
        </motion.div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default CookiePolicy; 