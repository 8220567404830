import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { motion } from 'framer-motion';
import '../../styles/pages/Legal.css';

const ReportScams = () => {
  const fadeUpVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1.0]
      }
    }
  };

  return (
    <div className="legal-page">
      <Navbar />
      
      <motion.div
        className="hero-section"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1451187580459-43490279c0fa?auto=format&fit=crop&q=80")'
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            Report Scams
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="hero-subtitle"
          >
            Protecting Our Community
          </motion.p>
        </div>
      </motion.div>

      <motion.section
        className="legal-content-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.div className="section-content" variants={fadeUpVariants}>
          <div className="legal-content">
            <h2>1. Common Scam Types</h2>
            <p>Be aware of these common scams that may misuse our name:</p>
            <ul>
              <li>Investment fraud schemes</li>
              <li>Impersonation of Glenhouse employees</li>
              <li>Fake job offers</li>
              <li>Phishing attempts</li>
              <li>Counterfeit services</li>
            </ul>

            <h2>2. How to Identify Scams</h2>
            <p>Watch for these warning signs:</p>
            <ul>
              <li>Unsolicited investment opportunities</li>
              <li>Requests for personal or financial information</li>
              <li>Promises of guaranteed returns</li>
              <li>Pressure to act quickly</li>
              <li>Communications from non-official email addresses</li>
            </ul>

            <h2>3. Reporting Process</h2>
            <p>If you suspect a scam:</p>
            <ol>
              <li>Document all relevant information</li>
              <li>Save any communication records</li>
              <li>Report to our security team immediately</li>
              <li>Contact relevant authorities if necessary</li>
            </ol>

            <h2>4. Our Response</h2>
            <p>When you report a scam, we will:</p>
            <ul>
              <li>Investigate the incident thoroughly</li>
              <li>Take appropriate action to stop the scam</li>
              <li>Work with authorities when necessary</li>
              <li>Update affected parties on outcomes</li>
            </ul>

            <h2>5. Prevention Tips</h2>
            <p>Protect yourself by:</p>
            <ul>
              <li>Verifying all communications through official channels</li>
              <li>Never sharing sensitive information via email</li>
              <li>Checking official Glenhouse website for updates</li>
              <li>Being cautious of unexpected opportunities</li>
            </ul>

            <h2>6. Official Channels</h2>
            <p>Only trust communications from:</p>
            <ul>
              <li>Official @weareglenhouse.com email addresses</li>
              <li>Verified social media accounts</li>
              <li>Our official website: www.weareglenhouse.com</li>
            </ul>

            <h2>7. Contact Information</h2>
            <p>Report suspicious activities to:</p>
            <ul>
              <li>Email: security@weareglenhouse.com</li>
              <li>Online Form: www.weareglenhouse.com/report-scam</li>
            </ul>

            <h2>8. Additional Resources</h2>
            <p>For more information about protecting yourself from fraud, visit:</p>
            <ul>
              <li>Federal Trade Commission (FTC)</li>
              <li>Internet Crime Complaint Center (IC3)</li>
              <li>Better Business Bureau (BBB)</li>
            </ul>
          </div>
        </motion.div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default ReportScams; 