import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { motion } from 'framer-motion';
import '../../styles/pages/Legal.css';

const PrivacyPolicy = () => {
  const fadeUpVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1.0]
      }
    }
  };

  return (
    <div className="legal-page">
      <Navbar />
      
      <motion.div
        className="hero-section"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1516321318423-f06f85e504b3?auto=format&fit=crop&q=80")'
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            Privacy Policy
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="hero-subtitle"
          >
            How We Protect Your Information
          </motion.p>
        </div>
      </motion.div>

      <motion.section
        className="legal-content-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.div className="section-content" variants={fadeUpVariants}>
          <div className="legal-content">
            <h2>1. Information We Collect</h2>
            <p>We collect information that you provide directly to us, information we obtain automatically when you visit our website, and information from third-party sources.</p>

            <h2>2. How We Use Your Information</h2>
            <p>We use the information we collect to provide, maintain, and improve our services, to communicate with you, and to comply with legal obligations.</p>

            <h2>3. Information Sharing</h2>
            <p>We do not sell your personal information. We may share your information with service providers, business partners, and as required by law.</p>

            <h2>4. Data Security</h2>
            <p>We implement appropriate technical and organizational measures to protect your personal information against unauthorized access, alteration, disclosure, or destruction.</p>

            <h2>5. Your Rights and Choices</h2>
            <p>You have the right to access, correct, or delete your personal information. You may also opt out of certain data collection and use.</p>

            <h2>6. Cookies and Tracking Technologies</h2>
            <p>We use cookies and similar technologies to collect information about your browsing activities. You can control these through your browser settings.</p>

            <h2>7. International Data Transfers</h2>
            <p>Your information may be transferred to and processed in countries other than your own. We ensure appropriate safeguards are in place for such transfers.</p>

            <h2>8. Children's Privacy</h2>
            <p>Our services are not directed to children under 13. We do not knowingly collect personal information from children under 13.</p>

            <h2>9. Changes to This Policy</h2>
            <p>We may update this Privacy Policy from time to time. We will notify you of any material changes by posting the new policy on our website.</p>

            <h2>10. Contact Us</h2>
            <p>If you have questions about this Privacy Policy, please contact us at privacy@weareglenhouse.com.</p>
          </div>
        </motion.div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default PrivacyPolicy; 