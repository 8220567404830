import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/pages/ExecutiveBio.css';

const EXECUTIVE_BIOS = {
  'gregory-pizarro-jr': {
    name: 'Gregory Pizarro, Jr.',
    title: 'Founder & CEO',
    company: 'Glenhouse',
    image: '/images/executives/gregory-pizarro.jpg',
    linkedin: 'https://www.linkedin.com/in/gregorypizarrojr/',
    twitter: 'https://twitter.com/gregpizarrojr',
    instagram: 'https://instagram.com/gregpizarrojr',
    tiktok: 'https://tiktok.com/@gregpizarrojr',
    fullBio: `As the Chief Executive Officer of Glenhouse, Gregory Pizarro Jr. stands at the helm of a dynamic enterprise dedicated to transforming innovative ideas into market-leading ventures. With a visionary approach to business leadership, Gregory has established Glenhouse as a pioneering force across multiple industries.

    Under his leadership, Glenhouse has expanded into diverse sectors including sports management, cannabis innovation, air transportation, and entertainment promotion. Gregory's strategic vision combines entrepreneurial insight with a deep commitment to social progress, as evidenced by the establishment of the Glenhouse Foundation.

    His leadership philosophy centers on building strong, collaborative teams and fostering an environment where innovation thrives. The name Glenhouse itself reflects his personal connection to the mission, with "Glen" being his middle name and "House" representing the collaborative, family-like culture he has cultivated within the organization.

    Gregory's approach to business goes beyond traditional metrics of success, focusing on creating lasting impact and meaningful change in every sector Glenhouse enters. His ability to identify emerging opportunities and align them with social progress has become a hallmark of Glenhouse's success.`
  },
  'jovar-tualla': {
    name: 'Jovar Tualla',
    title: 'Special Assistant to the CEO',
    company: 'Glenhouse',
    image: '/images/executives/jovar-tualla.jpg',
    linkedin: '',
    twitter: '',
    instagram: '',
    tiktok: '',
    fullBio: `As Special Assistant to the CEO at Glenhouse, Jovar Tualla brings a dynamic blend of operational expertise and creative innovation to his role. His multifaceted background in operations management, digital marketing, and project execution makes him an invaluable asset in supporting the CEO's strategic initiatives and day-to-day operations.

    Since beginning his professional journey in 2016, Jovar has demonstrated exceptional growth and adaptability, progressing from entry-level positions to operations management. His expertise spans digital marketing, social media management, graphic design, and web development, providing him with a comprehensive understanding of modern business tools and technologies.

    In his role at Glenhouse, Jovar applies his diverse skill set to ensure seamless execution of key initiatives and projects. His attention to detail, proactive approach, and ability to navigate complex operational challenges contribute significantly to the efficiency of the CEO's office and the broader organization.

    Beyond his professional achievements, Jovar maintains a disciplined approach to personal growth, exemplified by his dedication to martial arts and creative pursuits. His commitment to continuous improvement and mentorship aligns perfectly with Glenhouse's values of innovation and excellence. Guided by the philosophy that perfection lies in the ability to continuously improve, Jovar brings this mindset to his work at Glenhouse, constantly seeking ways to enhance processes and drive organizational success.`
  },
  'christopher-king': {
    name: 'Christopher King, Esq.',
    title: 'Senior Counsel to the CEO',
    company: 'Glenhouse',
    image: '/images/executives/christopher-king.jpg',
    linkedin: 'https://www.linkedin.com/in/christopher-king-00343720/',
    twitter: '',
    instagram: '',
    tiktok: '',
    fullBio: `Christopher King serves as Senior Counsel to the CEO at Glenhouse, bringing extensive legal expertise and strategic insight to the organization. As a seasoned legal professional, Christopher plays a crucial role in guiding Glenhouse's growth while ensuring compliance and managing legal risk across all business ventures.

    His responsibilities encompass overseeing legal strategy, corporate governance, and regulatory compliance across Glenhouse's diverse portfolio of companies. Christopher's expertise is particularly valuable in navigating the complex regulatory landscapes of emerging industries such as cannabis and air transportation.

    With a background in corporate law and a deep understanding of business strategy, Christopher provides invaluable counsel that helps shape Glenhouse's strategic initiatives and business development opportunities. His work extends beyond traditional legal counsel, as he actively participates in strategic planning and business development discussions.

    Christopher's approach to legal counsel aligns perfectly with Glenhouse's innovative spirit, finding creative solutions to complex legal challenges while maintaining strict compliance with regulatory requirements.`
  },
  'alvean-lyons': {
    name: 'Dr. Alvean Lyons',
    title: 'Special Advisor to CEO',
    company: 'Glenhouse',
    image: '/images/executives/alvean-lyons.jpg',
    linkedin: 'https://www.linkedin.com/in/alvean-lyons-ph-d-50a63267/',
    twitter: '',
    instagram: '',
    tiktok: '',
    fullBio: `Dr. Alvean Lyons serves as Special Advisor to the CEO at Glenhouse, bringing a wealth of expertise in strategic planning and organizational development. With a Ph.D. and extensive experience in business consulting, Dr. Lyons provides crucial insights that help shape Glenhouse's strategic direction and organizational culture.

    In her role, Dr. Lyons focuses on optimizing organizational effectiveness, developing leadership capabilities, and ensuring that Glenhouse's growth aligns with its core values and mission. Her academic background combined with practical business experience allows her to bridge the gap between theoretical frameworks and real-world application.

    Dr. Lyons plays a vital role in developing and implementing strategic initiatives across Glenhouse's various divisions. Her expertise in organizational development has been instrumental in creating scalable processes and systems that support the company's rapid growth while maintaining operational excellence.

    As an advisor, she works closely with the CEO and executive team to ensure that Glenhouse's expansion into new markets and industries is supported by robust organizational structures and effective leadership development programs.`
  },
  'trevor-higgins': {
    name: 'Trevor Higgins',
    title: 'COO & President',
    company: 'Glenhouse & Glenhouse Cannabis',
    image: '/images/executives/trevor-higgins.jpg',
    linkedin: 'https://www.linkedin.com/in/trevmortgage/',
    twitter: '',
    instagram: '',
    tiktok: '',
    fullBio: `Trevor Higgins serves as both Chief Operating Officer of Glenhouse and President of Glenhouse Cannabis, bringing operational excellence and industry expertise to these crucial roles. His dual position places him at the intersection of Glenhouse's overall operational strategy and its specific focus on cannabis innovation.

    As COO, Trevor oversees the day-to-day operations across Glenhouse's diverse portfolio, ensuring efficiency, scalability, and sustainable growth. His operational expertise has been instrumental in streamlining processes and optimizing performance across all divisions.

    In his role as President of Glenhouse Cannabis, Trevor leads the company's initiatives in the cannabis industry, focusing on responsible research, sustainable practices, and innovative market strategies. His leadership has been crucial in establishing Glenhouse as a respected player in the evolving cannabis market.

    Trevor's background in operational management and his deep understanding of emerging markets have proven invaluable in navigating the complex landscape of the cannabis industry while maintaining operational excellence across all of Glenhouse's ventures.`
  },
  'sheila-kearney': {
    name: 'Dr. Sheila M. Kearney',
    title: 'Executive Director',
    company: 'Glenhouse Foundation',
    image: '/images/executives/sheila-kearney.jpg',
    linkedin: 'https://www.linkedin.com/in/dr-sheila-m-kearney-305b0214/',
    twitter: '',
    instagram: '',
    tiktok: '',
    email: 'sheila@glenhousefoundation.org',
    fullBio: `As Executive Director of the Glenhouse Foundation, Dr. Sheila M. Kearney brings a wealth of experience in education leadership, research, and diversity-inclusion-belonging consulting to drive the foundation's mission and impact. Her extensive background in positive psychology, social research, and educational administration makes her uniquely qualified to lead the foundation's philanthropic initiatives.

    Throughout her distinguished career, Dr. Kearney has held several prestigious positions, including executive roles at Gallup where she served as Executive Director of the International Positive Psychology Institute and Vice President of Social Audits. Her work included leading national polling on critical social issues and developing Gallup's Workplace Inclusiveness Audit and consulting practice.

    Dr. Kearney's commitment to education and leadership development is evidenced by her significant contributions to the Thurgood Marshall College Fund, where she served as Chief Academic Officer and co-founded multiple educational initiatives. Her experience extends to roles with the Executive Leadership Council, The Prudential Foundation, and Harvard University's various research and educational programs.

    With a doctorate from Harvard University in Administration, Planning and Social Policy, Dr. Kearney brings academic rigor and practical expertise to her role at the Glenhouse Foundation. Her approach combines strategic thinking with a deep understanding of educational and social impact programs, ensuring that the foundation's initiatives create meaningful and lasting change in communities.`
  },
  'isaiah-reese': {
    name: 'Isaiah Reese',
    title: 'Chief Strategy Officer',
    company: 'Glenhouse',
    image: '/images/executives/isaiah-reese.jpg',
    linkedin: '',
    twitter: '',
    instagram: '',
    tiktok: '',
    fullBio: `As Chief Strategy Officer of Glenhouse, Isaiah Reese plays a pivotal role in shaping the strategic direction of the company's future growth. With a keen eye for market opportunities and a deep understanding of business development, Isaiah leads the development and execution of Glenhouse's strategic initiatives.

    His responsibilities include identifying new business opportunities, developing strategic partnerships, and ensuring that Glenhouse's various divisions align with the company's overall vision and goals. Isaiah's strategic insight has been instrumental in Glenhouse's successful expansion into multiple industries.

    With a background in strategic planning and business development, Isaiah brings a unique perspective to Glenhouse's executive team. His ability to analyze market trends and identify emerging opportunities has helped position Glenhouse at the forefront of several innovative industries.

    Isaiah's strategic leadership extends beyond traditional business development, as he works to ensure that Glenhouse's growth aligns with its commitment to social impact and sustainable business practices.`
  },
  'braxton-miller': {
    name: 'Braxton Miller',
    title: 'Chief Brand Officer',
    company: 'Glenhouse',
    image: '/images/executives/braxton-miller.jpg',
    linkedin: 'https://www.linkedin.com/in/braxtonmiller13/',
    twitter: 'https://twitter.com/braxtonmiller5',
    instagram: 'https://www.instagram.com/braxtonmiller/',
    tiktok: 'https://www.tiktok.com/@braxtonm1ller',
    fullBio: `Braxton Miller serves as Chief Brand Officer at Glenhouse, where he leads the company's brand strategy and marketing initiatives across all divisions. With a background in professional sports and brand development, Braxton brings a unique perspective to building and elevating the Glenhouse brand.

    As CBO, Braxton is responsible for developing and implementing comprehensive brand strategies that align with Glenhouse's diverse portfolio of businesses. His experience as a former professional athlete gives him unique insights into sports marketing and athlete brand development, particularly valuable for Glenhouse Sports.

    Braxton's role encompasses overseeing brand partnerships, marketing campaigns, and public relations initiatives. His vision for the Glenhouse brand emphasizes innovation, excellence, and social impact, reflecting the company's core values.

    Under his leadership, the Glenhouse brand has evolved to represent not just a business enterprise, but a movement that champions innovation, inclusivity, and positive social change across multiple industries.`
  },
  'colin-cox': {
    name: 'Colin Cox',
    title: 'President',
    company: 'Glenhouse Sports',
    image: '/images/executives/colin-cox.jpg',
    linkedin: '',
    twitter: '',
    instagram: '',
    tiktok: '',
    fullBio: `Colin Cox leads Glenhouse Sports as its President, bringing extensive experience in sports management and athlete development to the role. His leadership has been instrumental in establishing Glenhouse Sports as a innovative force in sports management and athlete representation.

    Under Colin's direction, Glenhouse Sports has developed comprehensive programs for athlete development, career management, and post-career transition. His approach combines traditional sports management with innovative strategies for brand development and career longevity.

    Colin's vision for Glenhouse Sports extends beyond conventional athlete representation, incorporating elements of personal development, financial planning, and brand building. His leadership has helped create a unique ecosystem that supports athletes throughout their careers and beyond.

    With a deep understanding of both the business and human aspects of sports management, Colin continues to drive innovation in how athletes are represented and supported in their professional journeys.`
  },
  'markus-pitchford': {
    name: 'Markus Pitchford',
    title: 'President',
    company: 'Glenhouse Strategies',
    image: '/images/executives/markus-pitchford.jpg',
    linkedin: '',
    twitter: '',
    instagram: '',
    tiktok: '',
    fullBio: `As President of Glenhouse Strategies, Markus Pitchford leads the company's strategic consulting and business transformation initiatives. His role involves developing and implementing strategic solutions that help organizations navigate complex business challenges, political landscapes, and crisis situations to achieve sustainable growth.

    Markus brings extensive experience in business strategy, political consulting, and crisis management to his position. His expertise in political strategy and crisis management has been instrumental in helping clients navigate sensitive situations, manage reputational challenges, and achieve their strategic objectives. Under his leadership, Glenhouse Strategies has become known for its innovative approach to strategic planning and crisis resolution.

    His expertise spans multiple industries and specialties, with particular focus on political consulting and crisis management. Markus's approach emphasizes practical solutions that deliver measurable results while maintaining alignment with clients' long-term objectives, whether in business strategy, political campaigns, or crisis situations.

    Through his leadership, Glenhouse Strategies has developed a reputation for delivering transformative solutions that help organizations adapt and thrive in rapidly changing business and political environments. His unique blend of strategic insight, political acumen, and crisis management expertise makes him a valuable advisor to clients facing complex challenges across various sectors.`
  },
  'corey-silvia': {
    name: 'Corey Silvia',
    title: 'Chief Technology Officer',
    company: 'Glenhouse',
    image: '/images/executives/corey-silvia.jpg',
    linkedin: 'https://www.linkedin.com/in/coreysilvia/',
    twitter: 'https://twitter.com/realcoreysilvia',
    instagram: 'https://www.instagram.com/coreysilvia/',
    tiktok: 'https://www.tiktok.com/@silviacorey',
    fullBio: `Corey Silvia serves as Chief Technology Officer at Glenhouse, where he leads the company's technological initiatives and digital transformation efforts. His role is crucial in ensuring that technology serves as a strategic enabler across all of Glenhouse's diverse business ventures.

    Corey has over 5 years of experience at Apple, where he honed his expertise in technology and innovation. He has also worked as an IT manager for many years, bringing a wealth of knowledge in managing complex IT environments. Additionally, Corey has a background in media, specializing in photography and videography.

    His skills extend to motorsports management, contract negotiations, and marketing for motorsports, making him a versatile asset to the team. As CTO, Corey is responsible for developing and implementing technology strategies that support Glenhouse's growth and innovation objectives. His expertise spans across various technological domains, including digital platforms, data analytics, and emerging technologies. He oversees all web design and web application development for both Glenhouse's portfolio of companies and their clients, ensuring consistent excellence and innovation across all digital properties.

    Under his leadership, Glenhouse has embraced cutting-edge technologies to enhance operational efficiency and create competitive advantages across its portfolio companies. Corey's approach to technology leadership emphasizes scalability, security, and innovation while ensuring that technological solutions align with business objectives. His team delivers sophisticated web solutions that combine modern design aesthetics with powerful functionality, helping clients achieve their digital transformation goals.

    His vision for technology at Glenhouse extends beyond traditional IT functions, focusing on how technology can drive business transformation and create new opportunities across all divisions. Through his expertise in web development and digital solutions, he continues to push the boundaries of what's possible in digital experiences for both Glenhouse and its clients.`
  }
};

const ExecutiveBio = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  
  console.log('Attempting to load bio for ID:', id);
  console.log('Available bio IDs:', Object.keys(EXECUTIVE_BIOS));
  
  // Clean up the incoming ID to match our format
  const cleanId = id?.toLowerCase()
    .replace(/,?\s+jr\.?/i, '-jr')
    .replace(/,?\s+esq\.?/i, '')
    .replace(/[,.\s]+/g, '-')
    .replace(/[^a-z0-9-]/g, '')
    .replace(/-+/g, '-');
    
  console.log('Cleaned ID:', cleanId);
  
  const executive = EXECUTIVE_BIOS[cleanId];
  console.log('Found executive:', executive ? 'Yes' : 'No');

  useEffect(() => {
    console.log('URL Parameter:', id);
    console.log('Available Bios:', Object.keys(EXECUTIVE_BIOS));
    console.log('Found Executive:', executive);
    
    window.scrollTo(0, 0);
    if (!executive) {
      navigate('/executive-committee');
    }
  }, [executive, navigate, id]);

  if (!executive) return null;

  return (
    <div className="executive-bio-page">
      <Navbar />
      
      <motion.div
        className="hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            {executive.title}
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="hero-subtitle"
          >
            {executive.company}
          </motion.p>
        </div>
      </motion.div>

      <motion.div
        className="bio-hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="bio-hero-content">
          <motion.div
            className="executive-image"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            <img src={executive.image} alt={executive.name} />
          </motion.div>
          <motion.div
            className="executive-info"
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
          >
            <h1>{executive.name}</h1>
            <h2>{executive.title}</h2>
            <h3>{executive.company}</h3>
          </motion.div>
        </div>
      </motion.div>

      <motion.section
        className="bio-content"
        initial={{ opacity: 0, y: 30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.7 }}
      >
        <div className="bio-text">
          {executive.fullBio.split('\n\n').map((paragraph, index) => (
            <p key={index}>{paragraph.trim()}</p>
          ))}
        </div>

        <motion.div 
          className="social-links-section"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.8, delay: 0.9 }}
        >
          <div className="social-links-container">
            <h3>Connect with {executive.name.split(',')[0]}</h3>
            <div className="social-links">
              <a href={`mailto:${executive.name.split(',')[0].toLowerCase().split(' ')[0]}@weareglenhouse.com`} className="social-link">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                  <path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 4l-8 5-8-5V6l8 5 8-5v2z"></path>
                </svg>
                <span>Email</span>
              </a>

              {executive.twitter && (
                <a href={executive.twitter} target="_blank" rel="noopener noreferrer" className="social-link">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                  </svg>
                  <span>X</span>
                </a>
              )}

              {executive.instagram && (
                <a href={executive.instagram} target="_blank" rel="noopener noreferrer" className="social-link">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/>
                  </svg>
                  <span>Instagram</span>
                </a>
              )}

              {executive.linkedin && (
                <a href={executive.linkedin} target="_blank" rel="noopener noreferrer" className="social-link">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M20.5 2h-17A1.5 1.5 0 002 3.5v17A1.5 1.5 0 003.5 22h17a1.5 1.5 0 001.5-1.5v-17A1.5 1.5 0 0020.5 2zM8 19H5v-9h3zM6.5 8.25A1.75 1.75 0 118.3 6.5a1.78 1.78 0 01-1.8 1.75zM19 19h-3v-4.74c0-1.42-.6-1.93-1.38-1.93A1.74 1.74 0 0013 14.19a.66.66 0 000 .14V19h-3v-9h2.9v1.3a3.11 3.11 0 012.7-1.4c1.55 0 3.36.86 3.36 3.66z"></path>
                  </svg>
                  <span>LinkedIn</span>
                </a>
              )}

              {executive.tiktok && (
                <a href={executive.tiktok} target="_blank" rel="noopener noreferrer" className="social-link">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor">
                    <path d="M19.59 6.69a4.83 4.83 0 01-3.77-4.25V2h-3.45v13.67a2.89 2.89 0 01-5.2 1.74 2.89 2.89 0 012.31-4.64c.298-.002.595.042.88.13V9.4a6.33 6.33 0 00-1-.08A6.34 6.34 0 003 15.66a6.34 6.34 0 0010.86 4.49v.02h3.45v-9.4a7.79 7.79 0 002.28.32V7.64a4.82 4.82 0 01-1-.1z"/>
                  </svg>
                  <span>TikTok</span>
                </a>
              )}
            </div>
          </div>
        </motion.div>
      </motion.section>

      <motion.div
        className="back-to-committee"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8, delay: 0.9 }}
      >
        <a href="/executive-committee">← Back to Executive Committee</a>
      </motion.div>
      
      <Footer />
    </div>
  );
};

export default ExecutiveBio; 