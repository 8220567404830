import React, { useState, useEffect, useRef } from 'react';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import '../styles/pages/About.css';

const CountingNumber = ({ start = 0, end, duration = 2000, prefix = '', suffix = '' }) => {
  const [count, setCount] = useState(start);
  const countRef = useRef(null);

  useEffect(() => {
    const startTime = Date.now();

    const updateCount = () => {
      const now = Date.now();
      const progress = Math.min((now - startTime) / duration, 1);
      const currentCount = Math.floor(start + (end - start) * progress);
      
      setCount(currentCount);

      if (progress < 1) {
        countRef.current = requestAnimationFrame(updateCount);
      }
    };

    countRef.current = requestAnimationFrame(updateCount);

    return () => {
      if (countRef.current) {
        cancelAnimationFrame(countRef.current);
      }
    };
  }, [start, end, duration]);

  return <span>{prefix}{count}{suffix}</span>;
};

const About = () => {
  const fadeUpVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1.0]
      }
    }
  };

  return (
    <div className="about-page">
      <Navbar />
      
      <motion.div
        className="hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            About Glenhouse
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="hero-subtitle"
          >
            Where Vision Meets Excellence
          </motion.p>
        </div>
      </motion.div>

      <motion.section
        className="mission-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        <motion.div className="section-content" variants={fadeUpVariants}>
          <h2>Our Mission</h2>
          <p>At Glenhouse, we are architects of transformation, dedicated to pioneering innovation across diverse and emerging industries. Our mission transcends traditional business boundaries, focusing on creating meaningful impact through strategic investments, entrepreneurial vision, and a commitment to social progress.</p>
          
          <p>We are driven by a multifaceted approach that spans key sectors:</p>
          
          <ul>
            <li><strong>Sports & Entertainment:</strong> Elevating athletic talent and entertainment platforms, creating opportunities that empower performers and connect global audiences.</li>
            <li><strong>Cannabis Innovation:</strong> Advancing the cannabis industry through responsible research, sustainable practices, and cutting-edge market strategies.</li>
            <li><strong>Air Travel & Transportation:</strong> Reimagining mobility and travel experiences with forward-thinking technologies and sustainable solutions.</li>
            <li><strong>Promotional Ventures:</strong> Developing platforms that amplify emerging talents in boxing, music, and other competitive domains.</li>
          </ul>

          <p>The Glenhouse Foundation stands at the core of our social impact, channeling our resources and expertise into philanthropic initiatives that address critical community needs, support education, and foster economic empowerment.</p>

          <p>We believe in the power of collaboration, strategic thinking, and a holistic approach to business. Our goal is not just to invest or create companies, but to build ecosystems that drive meaningful change, support emerging talents, and contribute to a more innovative and inclusive future.</p>
        </motion.div>
      </motion.section>

      <motion.section
        className="founder-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        <motion.div className="section-content" variants={fadeUpVariants}>
          <div className="founder-message">
            <h2>A Message from Our Founder</h2>
            <div className="message-content">
              <p>The name <em>Glenhouse</em> is more than just a company—it's a philosophy, a vision. "Glen" is my middle name, and it's a constant reminder of who I am and where I come from. But "House"—that's where the power lies. A house is a home. It's a place of refuge, of connection, of shared purpose.</p>
              <br />
              <p>At Glenhouse, we don't just build a team; we build a family. Every person here is valued, respected, and supported—not just as a colleague, but as someone who has a vital role in something bigger than themselves. It's a community rooted in trust, collaboration, and a shared commitment to success.</p>
              <br />
              <p>When I named this company, I wanted it to reflect not just what we do, but <em>how</em> we do it. And that's with a deep belief in the power of people coming together, of lifting one another up, of creating something that lasts. At Glenhouse, we're not just working side by side—we're building something meaningful, together. And that's what makes this more than just a company. It makes it a movement. A home for anyone who believes that together, we can achieve something extraordinary.</p>
            </div>
            <div className="founder-signature">
              <span className="founder-name">Gregory Pizaro Jr.</span>
              <span className="founder-title">Founder & CEO</span>
            </div>
          </div>
        </motion.div>
      </motion.section>

      <motion.section 
        className="stats-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        <motion.div 
          className="stats-grid"
          variants={{
            hidden: { opacity: 0, y: 30 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.8,
                ease: [0.25, 0.1, 0.25, 1.0]
              }
            }
          }}
        >
          <div className="stat-item">
            <div className="stat-number">
              <CountingNumber start={2025} end={2017} duration={2000} />
            </div>
            <p className="stat-label">Year Founded</p>
          </div>
          <div className="stat-item">
            <div className="stat-number">
              <CountingNumber start={0} end={12} duration={2000} />
            </div>
            <p className="stat-label">Portfolio Companies</p>
          </div>
          <div className="stat-item">
            <div className="stat-number">
              <CountingNumber start={0} end={50} duration={2000} prefix="$" suffix="M" />
            </div>
            <p className="stat-label">Capital Deployed</p>
          </div>
          <div className="stat-item">
            <div className="stat-number">
              <CountingNumber start={0} end={6} duration={2000} />
            </div>
            <p className="stat-label">Industry Sectors</p>
          </div>
        </motion.div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default About; 