import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import '../styles/components/Logo.css';

const COMPANY_DIVISIONS = [
  { text: 'AIR', link: '/air' },
  { text: 'CANNABIS', link: '/cannabis' },
  { text: 'ENTERTAINMENT', link: '/entertainment' },
  { text: 'PROMOTIONS', link: '/promotions' },
  { text: 'SPORTS', link: 'https://glenhousesports.com' },
  { text: 'STRATEGIES', link: 'https://glenhousestrategies.com' }
];

const Logo = () => {
  const [showProperties, setShowProperties] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      if (!isMobile) setShowProperties(false);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [isMobile]);

  const handleLogoClick = (e) => {
    if (isMobile) {
      e.preventDefault();
      setShowProperties(!showProperties);
    }
  };

  return (
    <>
      <Link 
        to="/" 
        className={`logo ${isHomePage ? 'home' : ''} ${showProperties ? 'menu-open' : ''}`} 
        onClick={handleLogoClick}
      >
        <img 
          src={isHomePage ? "/images/logos/logo-dark.svg" : "/images/logos/logo-light.svg"} 
          alt="Glenhouse Logo" 
          className={isHomePage ? 'main-logo' : ''}
        />
      </Link>

      {isMobile && showProperties && (
        <div className="properties-overlay">
          <div className="properties-container">
            <div className="properties-header">
              <img src="/images/logos/logo-dark.svg" alt="Glenhouse" />
              <button className="close-button" onClick={() => setShowProperties(false)}>
                <span>×</span>
              </button>
            </div>
            <div className="properties-grid">
              {COMPANY_DIVISIONS.map((division, index) => (
                division.link.startsWith('http') ? (
                  <a
                    key={division.text}
                    href={division.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="property-item"
                    onClick={() => setShowProperties(false)}
                  >
                    {division.text}
                  </a>
                ) : (
                  <Link
                    key={division.text}
                    to={division.link}
                    className="property-item"
                    onClick={() => setShowProperties(false)}
                  >
                    {division.text}
                  </Link>
                )
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Logo; 