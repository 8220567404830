import React, { useEffect } from 'react';
import { motion } from 'framer-motion';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/pages/ExecutiveCommittee.css';

const EXECUTIVES = [
  {
    name: 'Gregory Pizarro, Jr.',
    title: 'Founder & Chief Executive Officer',
    company: 'Glenhouse',
    image: '/images/executives/gregory-pizarro.jpg',
    linkedin: 'https://www.linkedin.com/in/gregorypizarrojr/',
    twitter: 'https://twitter.com/gregorypizarrojr',
    instagram: 'https://instagram.com/gregorypizarrojr',
    tiktok: 'https://tiktok.com/@gregorypizarrojr',
    bio: 'Leading Glenhouse\'s vision of transforming innovative ideas into market-leading enterprises.'
  },
  {
    name: 'Jovar Tualla',
    title: 'Special Assistant to the CEO',
    company: 'Glenhouse',
    image: '/images/executives/jovar-tualla.jpg',
    linkedin: '',
    twitter: '',
    instagram: '',
    tiktok: '',
    bio: 'Supporting strategic initiatives and operations with versatile expertise in digital innovation and project execution.'
  },
  {
    name: 'Christopher King, Esq.',
    title: 'Senior Counsel to the CEO',
    company: 'Glenhouse',
    image: '/images/executives/christopher-king.jpg',
    linkedin: 'https://www.linkedin.com/in/christopher-king-00343720/',
    twitter: '',
    instagram: '',
    tiktok: '',
    bio: 'Providing strategic legal guidance to support Glenhouse\'s growth and innovation.'
  },
  {
    name: 'Dr. Alvean Lyons',
    title: 'Special Advisor to CEO',
    company: 'Glenhouse',
    image: '/images/executives/alvean-lyons.jpg',
    linkedin: 'https://www.linkedin.com/in/alvean-lyons-ph-d-50a63267/',
    twitter: '',
    instagram: '',
    tiktok: '',
    bio: 'Bringing expertise in strategic planning and organizational development.'
  },
  {
    name: 'Trevor Higgins',
    title: 'Chief Operating Officer & President',
    company: 'Glenhouse & Glenhouse Cannabis',
    image: '/images/executives/trevor-higgins.jpg',
    linkedin: 'https://www.linkedin.com/in/trevmortgage/',
    twitter: '',
    instagram: '',
    tiktok: '',
    bio: 'Driving operational excellence across Glenhouse\'s diverse portfolio.'
  },
  {
    name: 'Isaiah Reese',
    title: 'Chief Strategy Officer',
    company: 'Glenhouse',
    image: '/images/executives/isaiah-reese.jpg',
    linkedin: 'https://www.instagram.com/isaiahreese13/?hl=en',
    twitter: '',
    instagram: 'https://www.instagram.com/isaiahreese13/',
    tiktok: '',
    bio: 'Shaping the strategic direction of Glenhouse\'s future growth.'
  },
  {
    name: 'Braxton Miller',
    title: 'Chief Brand Officer',
    company: 'Glenhouse',
    image: '/images/executives/braxton-miller.jpg',
    linkedin: 'https://www.linkedin.com/in/braxtonmiller13/',
    twitter: 'https://twitter.com/braxtonmiller5',
    instagram: 'https://www.instagram.com/braxtonmiller/',
    tiktok: 'https://www.tiktok.com/@braxtonmiller',
    bio: 'Building and elevating the Glenhouse brand across all divisions.'
  },
  {
    name: 'Corey Silvia',
    title: 'Chief Technology Officer',
    company: 'Glenhouse',
    image: '/images/executives/corey-silvia.jpg',
    linkedin: 'https://www.linkedin.com/in/coreysilvia/',
    twitter: 'https://twitter.com/coreysilvia',
    instagram: 'https://www.instagram.com/coreysilvia/',
    tiktok: 'https://www.tiktok.com/@coreysilvia',
    bio: 'Spearheading technological innovation and digital transformation.'
  },
  {
    name: 'Colin Cox',
    title: 'President',
    company: 'Glenhouse Sports',
    image: '/images/executives/colin-cox.jpg',
    linkedin: 'https://www.linkedin.com/in/colin-giuseppe-cox/',
    twitter: '',
    instagram: '',
    tiktok: '',
    bio: 'Leading innovation in sports management and athlete development.'
  },
  {
    name: 'Markus Pitchford',
    title: 'President',
    company: 'Glenhouse Strategies',
    image: '/images/executives/markus-pitchford.jpg',
    linkedin: 'https://www.linkedin.com/in/markus-pitchford-a80a8879/',
    twitter: '',
    instagram: '',
    tiktok: '',
    bio: 'Driving strategic initiatives and business transformation.'
  },
  {
    name: 'Dr. Sheila M. Kearney',
    title: 'Executive Director',
    company: 'Glenhouse Foundation',
    image: '/images/executives/sheila-kearney.jpg',
    linkedin: '',
    twitter: '',
    instagram: '',
    tiktok: '',
    bio: 'Leading philanthropic initiatives and social impact programs with decades of experience in education and diversity.'
  }
];

const ExecutiveCommittee = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const fadeUpVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1.0]
      }
    }
  };

  return (
    <div className="executive-page">
      <Navbar />
      
      <motion.div
        className="hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            Executive Committee
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="hero-subtitle"
          >
            Meet the visionaries shaping the future of Glenhouse
          </motion.p>
        </div>
      </motion.div>

      <motion.section
        className="leadership-intro"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.div className="intro-content" variants={fadeUpVariants}>
          <h2>Leadership that Inspires</h2>
          <p>Our executive team brings together diverse expertise and innovative thinking to drive Glenhouse forward. Each leader contributes unique insights and experience to our mission of transforming industries and creating lasting impact.</p>
        </motion.div>
      </motion.section>

      <motion.section
        className="executives-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "-100px" }}
      >
        <motion.div 
          className="executives-grid"
          variants={{
            hidden: { opacity: 0, y: 30 },
            visible: {
              opacity: 1,
              y: 0,
              transition: {
                duration: 0.8,
                ease: [0.25, 0.1, 0.25, 1.0]
              }
            }
          }}
        >
          {EXECUTIVES.map((executive, index) => (
            <div
              key={index}
              className="executive-card"
            >
              <a 
                href={`/bio/${executive.name
                  .toLowerCase()
                  .replace(/,?\s+jr\.?/i, '-jr')
                  .replace(/,?\s+esq\.?/i, '')
                  .replace(/dr\.\s+/i, '')
                  .replace(/\s+[a-z]\.\s+/i, '-')
                  .replace(/[,.\s]+/g, '-')
                  .replace(/[^a-z0-9-]/g, '')
                  .replace(/-+/g, '-')}`}
                className="card-link"
              >
                <div className="card-image">
                  <img src={executive.image} alt={executive.name} />
                  <div className="card-overlay">
                    <span className="bio-link">View Bio</span>
                  </div>
                </div>
                <div className="card-content">
                  <h3>{executive.name}</h3>
                  <p className="title">{executive.title}</p>
                  <p className="company">{executive.company}</p>
                  <p className="bio">{executive.bio}</p>
                </div>
              </a>
            </div>
          ))}
        </motion.div>
      </motion.section>
      
      <Footer />
    </div>
  );
};

export default ExecutiveCommittee; 