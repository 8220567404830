import React from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Logo from '../components/Logo';
import { motion } from 'framer-motion';
import '../styles/pages/Home.css';

const COMPANY_DIVISIONS = [
  { text: 'AIR', link: '/air' },
  { text: 'CANNABIS', link: '/cannabis' },
  { text: 'ENTERTAINMENT', link: '/entertainment' },
  { text: 'PROMOTIONS', link: '/promotions' },
  { text: 'SPORTS', link: 'https://glenhousesports.com' },
  { text: 'STRATEGIES', link: 'https://glenhousestrategies.com' }
];

const Home = () => {
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1
      }
    }
  };

  const itemVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.25, 0.1, 0.25, 1.0]
      }
    }
  };

  return (
    <>
      <Navbar />
      <motion.div 
        className="home-container"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <motion.div 
          className="logo-section"
          variants={itemVariants}
        >
          <Logo />
          
          <motion.div 
            className="divisions-container"
            variants={containerVariants}
          >
            {COMPANY_DIVISIONS.map((division, index) => (
              <motion.div
                key={division.text}
                className="division-item"
                variants={itemVariants}
              >
                {division.link.startsWith('http') ? (
                  <a 
                    href={division.link} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="division-link"
                  >
                    {division.text}
                  </a>
                ) : (
                  <Link to={division.link} className="division-link">
                    {division.text}
                  </Link>
                )}
              </motion.div>
            ))}
          </motion.div>
        </motion.div>

        <motion.div 
          className="bottom-navigation"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.8 }}
        >
          <a 
            href="https://glenhousefoundation.org" 
            target="_blank" 
            rel="noopener noreferrer" 
            className="nav-link"
          >
            The Glenhouse Foundation
          </a>
          <div>
            <Link to="/about" className="nav-link">
              About Glenhouse
            </Link>
            <Link to="/executive-committee" className="nav-link">
              Executive Committee
            </Link>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default Home;