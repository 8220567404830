import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import { motion } from 'framer-motion';
import '../styles/pages/ComingSoon.css';

const ComingSoon = () => {
  const location = useLocation();
  const getDivisionName = () => {
    const path = location.pathname.replace('/', '');
    return `Glenhouse ${path.charAt(0).toUpperCase() + path.slice(1)}`;
  };

  return (
    <div className="coming-soon-page">
      <Navbar />
      
      <motion.section 
        className="hero-section"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.8 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.2 }}
          >
            {getDivisionName()}
          </motion.h1>
        </div>
      </motion.section>

      <motion.div 
        className="coming-soon-content"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <h2>Coming Soon</h2>
        <p>We're working on something exciting. Stay tuned!</p>
        
        <div className="division-links">
          <Link to="/air" className="division-link">Air</Link>
          <Link to="/cannabis" className="division-link">Cannabis</Link>
          <Link to="/entertainment" className="division-link">Entertainment</Link>
          <Link to="/promotions" className="division-link">Promotions</Link>
          <a href="https://glenhousesports.com" target="_blank" rel="noopener noreferrer" className="division-link">Sports</a>
          <a href="https://glenhousestrategies.com" target="_blank" rel="noopener noreferrer" className="division-link">Strategies</a>
        </div>

        <Link to="/" className="back-home">
          Back to Home
        </Link>
      </motion.div>

      <motion.div 
        className="contact-info"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        <h2>Contact Us</h2>
        <p>Email: info@weareglenhouse.com</p>
      </motion.div>

      <Footer />
    </div>
  );
};

export default ComingSoon; 