import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import About from './pages/About';
import ExecutiveCommittee from './pages/ExecutiveCommittee';
import ExecutiveBio from './pages/ExecutiveBio';
import ComingSoon from './pages/ComingSoon';
import TermsOfUse from './pages/legal/TermsOfUse';
import PrivacyPolicy from './pages/legal/PrivacyPolicy';
import CookiePolicy from './pages/legal/CookiePolicy';
import EthicsGuidelines from './pages/legal/EthicsGuidelines';
import ReportScams from './pages/legal/ReportScams';
import WhistleblowerPolicy from './pages/legal/WhistleblowerPolicy';

const App = () => {
  return (
    <>
      <Helmet>
        <title>Glenhouse</title>
        <meta name="description" content="Welcome to Glenhouse - Where Vision Meets Excellence" />
        <meta name="keywords" content="glenhouse, executive leadership, business, innovation, strategy" />
      </Helmet>
      <Routes>
        {/* Main Routes */}
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/executive-committee" element={<ExecutiveCommittee />} />
        <Route path="/bio/:id" element={<ExecutiveBio />} />
        
        {/* Legal Routes */}
        <Route path="/legal/terms" element={<TermsOfUse />} />
        <Route path="/legal/privacy" element={<PrivacyPolicy />} />
        <Route path="/legal/cookies" element={<CookiePolicy />} />
        <Route path="/legal/ethics" element={<EthicsGuidelines />} />
        <Route path="/legal/report-scams" element={<ReportScams />} />
        <Route path="/legal/whistleblower" element={<WhistleblowerPolicy />} />
        
        {/* External Redirects */}
        <Route path="/foundation" element={
          <ExternalRedirect to="https://www.glenhousefoundation.org" />
        } />
        <Route path="/sports" element={
          <ExternalRedirect to="https://www.glenhousesports.com" />
        } />
        <Route path="/strategies" element={
          <ExternalRedirect to="https://www.glenhousestrategies.com" />
        } />
        
        {/* Coming Soon Pages */}
        <Route path="/air" element={<ComingSoon />} />
        <Route path="/cannabis" element={<ComingSoon />} />
        <Route path="/entertainment" element={<ComingSoon />} />
        <Route path="/promotions" element={<ComingSoon />} />
      </Routes>
    </>
  );
};

// Helper component for external redirects
const ExternalRedirect = ({ to }) => {
  React.useEffect(() => {
    window.location.href = to;
  }, [to]);

  return null;
};

export default App;