import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { motion } from 'framer-motion';
import '../../styles/pages/Legal.css';

const EthicsGuidelines = () => {
  const fadeUpVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1.0]
      }
    }
  };

  return (
    <div className="legal-page">
      <Navbar />
      
      <motion.div
        className="hero-section"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1453847668862-487637052f8a?auto=format&fit=crop&q=80")'
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            Ethics Guidelines
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="hero-subtitle"
          >
            Our Commitment to Integrity
          </motion.p>
        </div>
      </motion.div>

      <motion.section
        className="legal-content-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.div className="section-content" variants={fadeUpVariants}>
          <div className="legal-content">
            <h2>1. Core Values</h2>
            <p>Our ethics guidelines are built on our core values of integrity, transparency, and accountability. These principles guide every decision and action we take.</p>

            <h2>2. Business Conduct</h2>
            <p>We conduct our business with the highest standards of professional and personal ethics. This includes:</p>
            <ul>
              <li>Acting with honesty and integrity in all dealings</li>
              <li>Maintaining transparency in our operations</li>
              <li>Avoiding conflicts of interest</li>
              <li>Protecting confidential information</li>
            </ul>

            <h2>3. Fair Dealing</h2>
            <p>We are committed to dealing fairly with our clients, suppliers, competitors, and employees. We do not take unfair advantage through manipulation, concealment, or misrepresentation.</p>

            <h2>4. Anti-Corruption</h2>
            <p>We have a zero-tolerance policy for bribery and corruption. We comply with all applicable anti-corruption laws and regulations in every jurisdiction where we operate.</p>

            <h2>5. Environmental Responsibility</h2>
            <p>We are committed to environmental sustainability and responsible business practices that minimize our impact on the environment.</p>

            <h2>6. Workplace Ethics</h2>
            <p>We maintain a workplace environment that:</p>
            <ul>
              <li>Promotes diversity and inclusion</li>
              <li>Prevents discrimination and harassment</li>
              <li>Ensures equal opportunity</li>
              <li>Protects health and safety</li>
            </ul>

            <h2>7. Reporting Violations</h2>
            <p>We encourage reporting of any suspected violations of these guidelines through our whistleblower program, ensuring protection against retaliation.</p>

            <h2>8. Compliance</h2>
            <p>All employees, contractors, and partners are expected to comply with these guidelines. Violations may result in disciplinary action.</p>

            <h2>9. Training and Education</h2>
            <p>We provide regular training and education to ensure understanding and compliance with these guidelines.</p>

            <h2>10. Contact Information</h2>
            <p>For questions about our Ethics Guidelines or to report concerns, please contact our Ethics Office at ethics@weareglenhouse.com.</p>
          </div>
        </motion.div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default EthicsGuidelines; 