import React from 'react';
import Navbar from '../../components/Navbar';
import Footer from '../../components/Footer';
import { motion } from 'framer-motion';
import '../../styles/pages/Legal.css';

const WhistleblowerPolicy = () => {
  const fadeUpVariants = {
    hidden: { opacity: 0, y: 30 },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.8,
        ease: [0.25, 0.1, 0.25, 1.0]
      }
    }
  };

  return (
    <div className="legal-page">
      <Navbar />
      
      <motion.div
        className="hero-section"
        style={{
          backgroundImage: 'url("https://images.unsplash.com/photo-1450101499163-c8848c66ca85?auto=format&fit=crop&q=80")'
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 1.2 }}
      >
        <div className="hero-content">
          <motion.h1
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.3 }}
          >
            Whistleblower Policy
          </motion.h1>
          <motion.p
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8, delay: 0.5 }}
            className="hero-subtitle"
          >
            Speaking Up for What's Right
          </motion.p>
        </div>
      </motion.div>

      <motion.section
        className="legal-content-section"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <motion.div className="section-content" variants={fadeUpVariants}>
          <div className="legal-content">
            <h2>1. Purpose</h2>
            <p>This policy encourages and enables employees and others to raise serious concerns within Glenhouse without fear of retaliation.</p>

            <h2>2. Scope</h2>
            <p>This policy applies to:</p>
            <ul>
              <li>All employees (full-time, part-time, and temporary)</li>
              <li>Contractors and consultants</li>
              <li>Partners and suppliers</li>
              <li>Board members and executives</li>
            </ul>

            <h2>3. Reportable Conduct</h2>
            <p>Issues that should be reported include:</p>
            <ul>
              <li>Financial malpractice or impropriety</li>
              <li>Criminal activity</li>
              <li>Failure to comply with legal obligations</li>
              <li>Dangers to health and safety</li>
              <li>Environmental damage</li>
              <li>Discrimination or harassment</li>
              <li>Attempts to conceal any of the above</li>
            </ul>

            <h2>4. Protection</h2>
            <p>We are committed to protecting whistleblowers. This includes:</p>
            <ul>
              <li>Confidentiality of identity</li>
              <li>Protection against retaliation</li>
              <li>Support throughout the process</li>
              <li>Fair treatment</li>
            </ul>

            <h2>5. Reporting Process</h2>
            <p>To report a concern:</p>
            <ol>
              <li>Submit a report through our confidential reporting system</li>
              <li>Provide detailed information about the concern</li>
              <li>Include any supporting evidence</li>
              <li>Maintain confidentiality during the investigation</li>
            </ol>

            <h2>6. Investigation Process</h2>
            <p>All reports will be:</p>
            <ul>
              <li>Taken seriously and investigated promptly</li>
              <li>Handled confidentially</li>
              <li>Investigated by independent parties</li>
              <li>Documented thoroughly</li>
            </ul>

            <h2>7. Non-Retaliation</h2>
            <p>Glenhouse strictly prohibits retaliation against anyone who reports or participates in an investigation of a suspected violation in good faith.</p>

            <h2>8. False Reports</h2>
            <p>Knowingly making false allegations is a serious offense and may result in disciplinary action.</p>

            <h2>9. Confidentiality</h2>
            <p>We will maintain confidentiality to the fullest extent possible, consistent with the need to conduct an adequate investigation.</p>

            <h2>10. Contact Methods</h2>
            <p>You can report concerns through:</p>
            <ul>
              <li>Email: whistleblower@weareglenhouse.com</li>
              <li>Online Portal: www.weareglenhouse.com/speak-up</li>
            </ul>
          </div>
        </motion.div>
      </motion.section>

      <Footer />
    </div>
  );
};

export default WhistleblowerPolicy; 