import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/components/Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Navigation</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/about">About</Link></li>
            <li><a href="https://glenhousefoundation.org" target="_blank" rel="noopener noreferrer">Our Foundation</a></li>
            <li><Link to="/executive-committee">Executive Committee</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Divisions</h3>
          <ul>
            <li><Link to="/air">Air</Link></li>
            <li><Link to="/cannabis">Cannabis</Link></li>
            <li><Link to="/entertainment">Entertainment</Link></li>
            <li><Link to="/promotions">Promotions</Link></li>
            <li><a href="https://glenhousesports.com" target="_blank" rel="noopener noreferrer">Sports</a></li>
            <li><a href="https://glenhousestrategies.com" target="_blank" rel="noopener noreferrer">Strategies</a></li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Legal</h3>
          <ul>
            <li><Link to="/legal/terms">Terms of Use</Link></li>
            <li><Link to="/legal/privacy">Privacy Policy</Link></li>
            <li><Link to="/legal/cookies">Cookie Policy</Link></li>
            <li><Link to="/legal/ethics">Ethics Guidelines</Link></li>
            <li><Link to="/legal/report-scams">Report Scams</Link></li>
            <li><Link to="/legal/whistleblower">Whistleblower Policy</Link></li>
          </ul>
        </div>

        <div className="footer-section">
          <h3>Contact</h3>
          <ul>
            <li>Email: info@weareglenhouse.com</li>
            <li>Texas, United States</li>
          </ul>
        </div>
      </div>

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Glenhouse. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer; 